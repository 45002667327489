<template>
  
  <div class="survey">
    <v-guard :permissions="['office-section-quality-view-date-filter']">
      <div class="row" v-if="surveyStatistics.length > 0 || timespan.value != 'all'">
        <div class="col-1">
          <div class="widget">
            <v-select style="width: 270px"
              :options="timespans"
              transition="none"
              :filterable="false"
              v-model="timespan"
              :clearable="false"
              label="label"
              @option:selected="getActions"
            ><span slot="no-options">{{ $t('labels.no-values') }}</span>
           
            <template #open-indicator="{ attributes }">
              <span span v-bind="attributes"><i class="fas fa-filter"></i></span>
            </template>
            </v-select>
          </div>
        </div>
      </div>
    </v-guard>
    <div class="row" v-if="!isLoading">
      <div class="col-6 bl-1" v-for="survey in surveyStatistics" :key="survey.tag">
          <div class="widget" v-if="!survey.hide_on_statistics || $store.getters.permissions.includes('surveys-access-all')">
            <div class="widget-header">
              <div class="widget-header-label">
                <h3 style="font-size: 1.4rem;">{{ getName(survey.tag) }}</h3>
              </div>
              <div class="widget-header-toolbar pt-2">
                <h5>
                   <span style="font-family: Poppins; font-weight: 500; font-size: 1rem;">{{ $t('labels.respondent') }}</span> <span class="badge-count">{{ survey.number_who_answered }}</span>
                </h5>
              </div>
            </div>
            <div class="widget-body">
               <div class="row pb-2" >
                  <div class="col-7"></div>
                  <div class="col-3 text-center"><span style="font-family: Poppins; font-weight: 500; font-size: 1rem;">{{ $t('labels.average-rating') }}</span></div>
                  <div class="col-2 text-right"><span style="font-family: Poppins; font-weight: 500; font-size: 1rem;">{{ $t('labels.respondent') }}</span></div>
               </div>
              <div v-for="question in survey.questions" :key="question.ordering">
                <h5 class="pb-0 mb-0 mt-0" style="font-size: 1rem;"><b>{{ question.question.label }}</b></h5>

                <div class="row mb-0 pb-4" v-if="['number', 'opinion_scale', 'rating'].includes(question.question.type)">
                    <label class="col-7 col-form-label">{{ $t('labels.average-rating') }}</label>
                    <div class="col-3 pt-2">
                      <div class="widget-header-toolbar text-center">
                          {{ question.question.response }}
                      </div>
                    </div>
                    <div class="col-2 pt-2 text-right">
                        <span class="badge-count">{{ question.question.total }}</span>
                    </div>
                </div>
                <div class="pb-4" v-if="['choice', 'multiple_choice', 'choices', 'dropdown'].includes(question.question.type)">
                    <div class="row pt-0 mt-0" v-for="choice in question.choices" :key="choice.label">
                        <label class="col-7 col-form-label">{{ choice[0].label}}</label>
                        <div class="col-3 pt-2">
                          <div class="widget-header-toolbar text-center">
                             {{ choice[0].percentage }}%
                          </div>
                        </div>
                        <div class="col-2 pt-2 text-right">
                            <span class="badge-count">{{ choice[0].total }}</span> 
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextField from '@/components/ticket/localoffice/TextField';
import TextFieldOffice from '@/components/ticket/office/TextField';
import TextareaField from '@/components/ticket/localoffice/TextareaField';
import SelectField from '@/components/ticket/localoffice/SelectField';
import CheckboxField from '@/components/ticket/localoffice/CheckboxField';
import CheckboxFieldOffice from '@/components/ticket/office/CheckboxField';
import InfoBox from '@/components/InfoBox';
import BojAPI from '@/api/boj';

export default {
  name: 'survey-page',
  props: {
    id: Number,
  },
  data() {
    return {
      timespan: {
        "label": this.$t('labels.all-surveys'),
        "value": "all"
      },
      timespans: [
      {
        "label": this.$t('labels.all-surveys'),
        "value": "all"
      },
      {
        "label": this.$t('labels.surveys-last-year'),
        "value": "this-year"
      },
      {
        "label": this.$t('labels.surveys-last-6-months'),
        "value": "last-6-months"
      },
      {
        "label": this.$t('labels.surveys-last-3-months'),
        "value": "last-3-months"
      },

      ],
      isLoading: true,
      surveyStatistics: {}
    };
  },
  methods: {
    getName(tag) {
      if(tag == 'assessment') {
        return 'Resultat av BOJs allmänna brukarenkät';
      } else if(tag == 'interview') {
        return 'Intervju';
      } else {
        return this.surveyStatistics.find((el) => el.tag == tag).title;
      }
     
    },
    updateLocalOffice(ticket) {
      this.$emit('updateLocalOffice', ticket);
    },
    setData(payload) {
      this.localOffice = payload.data;
      this.isLoading = false;
    },
    getActions() {
        const filters = {
          timespan: this.timespan.value
        }
        BojAPI.getOfficeSurveyStatistics(this.localOffice.office_id, filters).then((response) => {
            this.surveyStatistics = response.data;
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    BojAPI.getLocalOffice(to.params.id)
        .then((response) => {
          next((vm) => vm.setData(response.data));
        })
        .catch((error) => {
        });
  },
  beforeRouteUpdate(to, from, next) {
    
    BojAPI.getLocalOffice(to.params.id).then((response) => {
      this.setData(response.data);
      next();
    });
  },
  mounted() {
    this.getActions();
  },
  components: {
    TextField,
    TextFieldOffice,
    TextareaField,
    SelectField,
    CheckboxField,
    CheckboxFieldOffice,
    InfoBox,
  },
};
</script>
